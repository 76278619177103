@import "variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "core/palette.scss";
@import "app-variables.scss";
@import "mixins.scss";
@import "custom-bootstrap.scss";
@import "core.scss";
@import "plugins.scss";
@import "customizer.scss";
@import "demo.scss";  // Remove this SCSS from production

table.nowrap th, table.nowrap td {
  white-space: nowrap;
}
.gameid-display {
  margin-bottom: 1.5rem;
  border: 1px solid #A6A9AE;
  border-radius: 1em;
  padding: 0.375rem 0.75rem;
}
.gameid-name {
  color: #75787d;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
